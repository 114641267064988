import moment from "moment";

export default {
  data() {
    return {
      timeoutSearch: null,
      serverParams: {},
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  methods: {
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    currencyexcelFormat(value) {
      if (!value) return "";
      return "Rp. " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    indonesianFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
    datetimeFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD-MM-YYYY H:m:s");
      } else {
        return "-";
      }
    },
    dateFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD-MM-YYYY");
      } else {
        return "-";
      }
    },
  },
};
