<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Data Ticket Closed</h3>
          </div>
          <div class="panel-body p-0">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :search-options="{
                enabled: true,
                trigger: 'keyup',
                placeholder: 'Pencarian',
              }"
              :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }"
              :rows="rows"
              :columns="columns"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-sync"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'maintain'">
                  {{ props.row.type_ticket }} Maintenance
                </span>

                <span v-if="props.column.field == 'action'">
                  <div>
                    <button
                      class="btn btn-icon btn-success m-r-5"
                      v-on:click="
                        getDetail(props.row.type_ticket, props.row.id_ticket)
                      "
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <router-link
                      v-if="props.row.status_ticket == 'Close'"
                      :to="'/ticket/report/' + props.row.id_ticket"
                      class="btn btn-icon btn-info m-r-5"
                      target="_blank"
                    >
                      <i class="fa fa-file"></i>
                    </router-link>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Detail Ticket</h5>
      </template>
      <div>
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td style="width: 110px">No Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.no_ticket }}</td>
            </tr>
            <tr>
              <td>Subject Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.subject_ticket }}</td>
            </tr>
            <tr>
              <td>Fill Ticket</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.fill_ticket }}</td>
            </tr>
            <tr>
              <td>Device</td>
              <td style="width: 5px">:</td>
              <td>{{ detail.nama_device }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <template #modal-footer>
        <button
          @click="closeModal"
          class="btn btn-block btn-info"
          variant="primary"
        >
          Close
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import userData from "../../../plugins/userData";
import deleteData from "@/plugins/deleteData";
import paginations from "@/plugins/paginations";
import formatting from "@/plugins/formatting";

export default {
  mixins: [userData, deleteData, paginations, formatting],
  data() {
    return {
      columns: [
        {
          label: "Nomor Ticket",
          field: "no_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Jenis Maintain",
          field: "maintain",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Problem",
          field: "cat_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Issue",
          field: "subject_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Time Start",
          field: "ticket_created",
          sortable: false,
          formatFn: this.datetimeFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Time End",
          field: "ticket_closed",
          sortable: false,
          formatFn: this.datetimeFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Status Ticket",
          field: "status_ticket",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Request By",
          field: "nama_karyawan",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        district: "",
        village: "",
      },
      detail: {
        no_ticket: "",
        subject_ticket: "",
        fill_ticket: "",
        nama_device: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      districtList: [],
      villageList: [],
      modalVisible: false,
      conditionMet: false,
    };
  },
  mounted() {
    this.fetchData();
    console.log(this.userLevel);
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      axios
        .get(this.$url+"/v2/report/ticket/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            district: this.filter.district,
            village: this.filter.village,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    getDetail(type, id) {
      this.isLoading = true;
      axios
        .get(this.$url+"/v2/ticket/detail/" + id, {
          headers: {
            token: this.userToken,
          },
          params: {
            type: type,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.showModal();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
  },
};
</script>